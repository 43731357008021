import { graphql, useStaticQuery } from "gatsby";
import { PressCategoryType } from "../Types/AcfGroupTypes/PressSingleType";

const PressCategoriesQuery = (): {
  categories: PressCategoryType[];
} => {
  const {
    allWpPressCategory: { nodes },
  } = useStaticQuery(graphql`
    {
      allWpPressCategory {
        nodes {
          id
          name
          slug
          uri
        }
      }
    }
  `);
  return {
    categories: nodes,
  };
};

export default PressCategoriesQuery;
