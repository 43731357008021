import * as React from "react";
import PressCategoriesMenu from "../../components/Menus/PressCategoriesMenu/PressCategoriesMenu";
import { PressSingleType } from "../../assets/Types/AcfGroupTypes/PressSingleType";
import PressResourceCard from "../../components/PressResourceCard/PressResourceCard";
import { useEffect, useState } from "react";

type ResourceGridContainerProps = {
  sectionClassname?: string | null;
  contClassname?: string | null;
  pressResources?: PressSingleType[] | null;
  currentCategory?: string;
};

const ResourcesGridContainer: React.FC<ResourceGridContainerProps> = ({
  sectionClassname,
  contClassname,
  currentCategory,
  pressResources,
}) => {
  const [contHeight, setContHeight] = useState(null);
  const [currentWidth, setCurrentWidth] = useState(null);

  const cards = () => {
    if (currentCategory) {
      return pressResources.filter(
        current =>
          current.pressCategories?.nodes[0]?.slug === currentCategory.trim()
      );
    }
    return pressResources;
  };

  useEffect(() => {
    setContHeight(document.querySelector<HTMLElement>("#contaHeight"));
    setCurrentWidth(window.innerWidth);
  }, []);

  return (
    <div className={`${sectionClassname}`} id="contaHeight">
      <PressCategoriesMenu
        contHeight={contHeight?.clientHeight}
        rowsNum={cards().length}
        currentWidth={currentWidth}
      />
      {cards() &&
        cards().map((pressResource, index) => (
          <PressResourceCard resourceSingle={pressResource} key={index} />
        ))}
    </div>
  );
};

export default ResourcesGridContainer;
