import React from "react";

import styles from "./PressHeroContainer.module.scss";

export type PressHeroContainerType = {
  headline: string;
  subcopy: string;
};

const PressHeroContainer: React.FC<PressHeroContainerType> = ({
  headline,
  subcopy,
}) => (
  <section className={styles.pressHeroContainer}>
    <div className="full-width shorter">
      <h1 className={styles.headline}>{headline}</h1>
      <p
        className={styles.subcopy}
        dangerouslySetInnerHTML={{ __html: subcopy }}
      />
    </div>
  </section>
);

export default PressHeroContainer;
