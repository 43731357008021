import * as React from "react";
import PressCategoriesQuery from "../../../assets/StaticQueries/PressCategories.query";
import { Link, navigate } from "gatsby";
import { BackgroundColors } from "../../../assets/Types/BackgroundColors.type";
import { useEffect, useState } from "react";
import SmallDownArrow from "../../SVGIcons/SmallDownArrow/SmallDownArrow";

import styles from "./PressCategoriesMenu.module.scss";

interface HeaderProps {
  backgroundColor?: BackgroundColors;
  setMenuOpen?: (arg0: boolean) => void;
  isSticky?: boolean;
  contHeight?: number;
  rowsNum?: number;
  currentWidth?: number;
}

const PressSideMenu: React.FC<HeaderProps> = ({
  backgroundColor,
  setMenuOpen,
  contHeight,
  rowsNum,
  currentWidth,
}) => {
  const { categories } = PressCategoriesQuery();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [sidebarClass, setHeaderClass] = useState(styles.pressFilterMenu);
  const [rowEnd, setRowEnd] = useState({ gridRowEnd: 29 });

  const [pathname, setPathname] = useState("");

  useEffect(() => {
    const locationPath = window.location.pathname;
    setPathname(locationPath.split("/")[2]);
  }, [pathname]);

  useEffect(() => {
    if (currentWidth) {
      if (currentWidth > 1023) {
        setRowEnd({ gridRowEnd: Math.ceil(rowsNum / 3) + 1 });
      } else if (currentWidth <= 1023 && currentWidth > 767) {
        setRowEnd({ gridRowEnd: Math.ceil(rowsNum / 2) + 1 });
      } else {
        setRowEnd({ gridRowEnd: Math.ceil(rowsNum) + 1 });
      }
    } else {
      setRowEnd({ gridRowEnd: 29 });
    }
  }, [currentWidth]);

  useEffect(() => {
    const sticky = 346;
    const scrollSideCallBack = () => {
      const startPoint =
        document.querySelector<HTMLElement>("#menuSection").offsetHeight;
      if (window.pageYOffset > sticky) {
        if (window.pageYOffset < startPoint + 20) {
          setHeaderClass(`${styles.pressFilterMenu} ${styles.isSticky}`);
          setIsSticky(true);
        } else {
          setHeaderClass(`${styles.pressFilterMenu}`);
          setHeaderClass(`${styles.pressFilterMenu} ${styles.isAbsolute}`);
          setIsSticky(false);
        }
      } else {
        setHeaderClass(`${styles.pressFilterMenu}`);
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", scrollSideCallBack);
    return () => {
      window.removeEventListener("scroll", scrollSideCallBack);
    };
  }, [contHeight]);

  const filterPress = (option: string) => {
    navigate(
      `/press/${option === "All Press" || option === "default" ? "" : option}`
    );
  };

  return (
    <section
      style={rowEnd}
      className={styles.resourcesMobileNavHeader}
      id="menuSection"
    >
      <div className={sidebarClass}>
        <h3>Filter Press</h3>
        <div className={styles.resourceNavContainer}>
          <div className={styles.inPageNavResources}>
            <div className={styles.resourcesNavBox}>
              <p
                className={`${styles.pressType} ${styles.hideForSmall} ${
                  styles.bodyLarge
                } ${isOpenFilter ? styles.resourceSelected : ""}`}
                onClick={() => {
                  setIsOpenFilter(!isOpenFilter);
                }}
              >
                By Category
                <SmallDownArrow />
              </p>
              <div
                className={`${styles.resourcesDropdown} ${
                  styles.hideForSmall
                } ${
                  isOpenFilter
                    ? styles.resourcesDropdownO
                    : styles.resourcesDropdownC
                }`}
              >
                <span className={styles.inPageMenu}>
                  <Link to="/press">All Press</Link>
                </span>
                {categories &&
                  categories.map((category, index) => (
                    <span className={styles.inPageMenu} key={index}>
                      <Link
                        to={`/press/${category.slug}/`}
                        id={`resources-id-${category.slug}`}
                      >
                        {category.name}
                      </Link>
                    </span>
                  ))}
              </div>
            </div>
            <div className={styles.showForSmall}>
              <select
                onChange={e => filterPress(e.target.value)}
                className={`${styles.resourceSelectDropdown} ${styles.selectDropdownN}`}
                value={pathname}
              >
                <option value="default">By Category</option>
                <option value="All Press">All Press</option>
                {categories &&
                  categories.map((category, index) => (
                    <option value={category.slug} key={index}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PressSideMenu;
