import React from "react";
import { ModuleItemType } from "../../assets/Types/AcfGroupTypes/ModuleContainer.type";

import styles from "./PressBannerContainer.module.scss";

type ModulePressProps = {
  pressBannerProps: ModuleItemType[];
};

const PressBannerContainer: React.FC<ModulePressProps> = ({
  pressBannerProps,
}) => {
  return (
    <section
      data-testid="div:pressModule"
      className={`full-width ${styles.pressModuleGrid}`}
    >
      {pressBannerProps.map((card, index) => (
        <div className={styles.iconCard} key={index}>
          <img
            className={`${styles.contactUsIcon} ${styles.small}`}
            src={card.icon.localFile.publicURL}
            alt={card.icon.altText}
          />
          <h3 className={styles.cardTitle}>{card.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: card.content }} />
        </div>
      ))}
    </section>
  );
};

export default PressBannerContainer;
