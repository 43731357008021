import React from "react";
import { PressSingleType } from "../../assets/Types/AcfGroupTypes/PressSingleType";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import styles from "./PressResourceCard.module.scss";

const PressResourceCard: React.FC<{
  resourceSingle: PressSingleType;
}> = ({ resourceSingle }) => {
  const image = getImage(resourceSingle?.pressC.pressImage.localFile);
  const categories = resourceSingle.pressCategories?.nodes.map(category =>
    category.name.toUpperCase()
  );

  const tags = [...categories].join(", ");

  return (
    <>
      {resourceSingle.pressC.pressExternalLink && (
        <a
          href={resourceSingle.pressC.pressExternalLink}
          target="_blank"
          rel="noreferrer"
          className={`${styles.card} ${styles.pressCard}`}
        >
          <GatsbyImage
            image={image}
            alt={resourceSingle?.pressC.pressImage.altText || ""}
          />
          <div data-testid="div:resourcesText" className={styles.resourcesText}>
            <span className={styles.cardCategory}>{tags}</span>
            <h2 className={styles.cardTitle}>{resourceSingle.title}</h2>
          </div>
        </a>
      )}
      {!resourceSingle.pressC.pressExternalLink && (
        <Link
          to={resourceSingle.uri}
          className={`${styles.card} ${styles.pressCard}`}
        >
          <GatsbyImage
            image={image}
            alt={resourceSingle?.pressC.pressImage.altText || ""}
          />
          <div className={styles.resourcesText}>
            <span className={styles.cardCategory}>{tags}</span>
            <h2 className={styles.cardTitle}>{resourceSingle.title}</h2>
          </div>
        </Link>
      )}
    </>
  );
};

export default PressResourceCard;
