import * as React from "react";
import { graphql } from "gatsby";
import MainLayout from "../../layouts/main";
import { YoastSEOType } from "../../assets/Types";
import {
  PressSingleType,
  HeroSType,
} from "../../assets/Types/AcfGroupTypes/PressSingleType";
import { ModuleItemType } from "../../assets/Types/AcfGroupTypes/ModuleContainer.type";
import SEO from "gatsby-plugin-wpgraphql-seo";
import ResourcesGridContainer from "../../containers/ResourcesGridContainer/ResourcesGridContainer";
import PressBannerContainer from "../../containers/PressBannerContainer/PressBannerContainer";
import { ResourcesRelationshipItemType } from "../../assets/Types/AcfGroupTypes";
import { deIndexSeo } from "../../utils/deIndexSeo";
import PressHeroContainer from "@containers/PressHeroContainer";

interface Resources {
  nodes: ResourcesRelationshipItemType[];
}

type PressProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroSingleP: HeroSType;
      pressPg: {
        pressRelationship: PressSingleType[];
      };
      iconCardsP: {
        iconCardsList: ModuleItemType[];
      };
    };
    title: string;
  };
};

const PressFilterPage: React.FC<{
  data: PressProps;
  pageContext: { id: string; slug: string };
}> = ({ data, pageContext }) => {
  const { slug: currentCategory } = pageContext;
  const { wpPage } = data;

  const {
    template: { heroSingleP, pressPg, iconCardsP },
    title,
  } = wpPage;

  return (
    <MainLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <PressHeroContainer
        headline={heroSingleP.title}
        subcopy={heroSingleP.subCopy}
      />
      <ResourcesGridContainer
        sectionClassname={`resources__grid full-width pad-bottom--large-sans-header`}
        pressResources={pressPg.pressRelationship}
        currentCategory={currentCategory}
      />
      <PressBannerContainer pressBannerProps={iconCardsP?.iconCardsList} />
    </MainLayout>
  );
};

export default PressFilterPage;

export const query = graphql`
  query PressFilterQuery {
    wpPage(id: { eq: "cG9zdDo0MjE0" }) {
      id
      ...YoastSEOFragment
      template {
        ... on WpPressTemplate {
          templateName
          heroSingleP {
            title: heroSingleTitle
            subCopy: heroSingleSubcopy
          }
          pressPg {
            pressRelationship {
              ... on WpPressSingle {
                id
                title
                slug
                uri
                pressCategories {
                  nodes {
                    id
                    name
                    slug
                    uri
                  }
                }
                pressC {
                  pressExternalLink
                  pressWysiwyg
                  pressImage {
                    altText
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          formats: [WEBP, JPG, AUTO]
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          iconCardsP {
            iconCardsList {
              content: subcopy
              title
              icon: image {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`;
